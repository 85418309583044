
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        














































.generic-popup {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: calc(100vw - 4rem);
  max-width: 106.5rem;
  background: $white;
  border-radius: $border-radius;

  @include mq(m) {
    flex-wrap: nowrap;
  }

  @include mq(xl) {
    width: 106.5rem;
  }
}

.generic-popup__panel__new,
.generic-popup__panel__activate {
  flex-basis: 100%;
  padding: 0 $spacing * 1.5;

  @include mq(m) {
    padding: $spacing * 3 $spacing * 2;
  }

  @include mq(xl) {
    padding: $spacing * 4 $spacing * 3;
  }
}

.generic-popup__panel__new {
  @include mq($until: m) {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 2;
  }

  .btn__icon {
    display: none;
  }
}

.generic-popup__panel__activate {
  background: $c-light-gray;

  @include mq($until: m) {
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 3;
  }
}

.generic-popup__panel__title {
  @extend %fw-medium;

  display: block;
  margin: 0 0 $spacing;
  font-family: $ff-alt;
  font-size: 3.2rem;
  line-height: 1.25;
}

.generic-popup__panel__text {
  @extend %fw-light;

  margin: 0 0 $spacing;
  font-size: 1.8rem;
  line-height: 1.66;
}

.generic-popup__panel__activate__link {
  font-size: 1.8rem;
}
